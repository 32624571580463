import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { withContext } from '../state/AppState';
import './DataPanel.scss';

export const buttonBarButtons = [
  {name:"Post", link:"/admin/blogpost"},
  {name:"Dyn.Text", link:"/admin/dynamic_section"},
  //{name:"Users", link:"/admin/users"},
];

export const ButtonBar = withContext((props) => {
  const { theme } = props.context;
  const ukButtonTheme = (theme==="dark")?"uk-button-secondary":"uk-button-default";
  return (
    <div className="uk-flex uk-flex-wrap uk-flex-left uk-margin-bottom" style={{width:'100%'}}>
      {(props.buttons.map((button,i)=>{
        if(button.onClick) { return (
          <div style={{ height: "2em"}} key={`button_bar_link_${i}`} className={button.divider?`uk-button-link uk-margin-auto-left uk-margin-small-right`:`uk-margin-small-right`}>
            <button className={`uk-button ${ukButtonTheme} uk-button-small`} onClick={button.onClick}>{button.name}</button>
          </div>
        );} else if(button.link) { return (
          <Link key={`button_bar_${i}`} to={button.link} className={button.divider?`uk-margin-auto-left uk-margin-small-right`:`uk-margin-small-right`}>
            <div style={{ height: "2em"}}>
              <span className={`uk-button ${ukButtonTheme} uk-button-small`}>{button.name}</span>
            </div>
          </Link>
        );} else { return (
          <></>
        );}
      }))}
    </div>
  );
});

class DataPanel extends PureComponent {
  state = {
    currentEntity: {},
    currentEntityId: null
  }
  componentDidUpdate(prevProps, prevState) {
    if(this.props.match.params !== prevProps.match.params) {
      this.refreshEntity();
    }
    if(this.state.currentEntityId !== prevState.currentEntityId) {
      this.refreshEntity();
    }
  }
  componentDidMount() {
    if(this.props.match && this.props.match.params) {
      this.refreshEntity();
    }
  }
  refreshEntity = () => {
    const { entityId } = this.props.match.params;
    if(entityId && entityId!=="new") {
      this.props.resource.getById(entityId)
        .then(res=>{
          this.setState({
            currentEntity:res,
            currentEntityId:entityId
          });
        });
    } else {
      //reset all fields
      [...document.querySelectorAll('input[type="text"], textarea')]
        .forEach(field=>{field.value="";});
      this.setState({
        currentEntity:{},
        currentEntityId:null
      });
    }
  };
  newButtonOCL = (e) => {
    e.preventDefault();
    const { notification, signOut, changeRoute } = this.props.context;
    const modifiedEntity = this.props.processEditModeFields(this.state.currentEntityId, this.state.currentEntity);
    this.props.resource.add(modifiedEntity)
      .then((res)=>{
        if(res.error) {
          if(res.error.name === "TokenExpiredError") {
            notification("Token expired. Please login again.");
            signOut();
          } else {
            notification(res.error);
          }
        } else {
          notification("Success");
          // this.dispatch.updatePosts();
          changeRoute(`/admin/${this.props.resource.entity}/${res.id}/edit`);
          window.location.reload();
        }
      });
  };
  saveButtonOCL = (e) => {
    e.preventDefault();
    const { notification, signOut } = this.props.context;
    const modifiedEntity = this.props.processEditModeFields(this.state.currentEntityId, this.state.currentEntity);
    this.props.resource.update(this.state.currentEntityId, modifiedEntity)
      .then((res)=>{
        if(res.error) {
          if(res.error.name === "TokenExpiredError") {
            notification("Token expired. Please login again.");
            signOut();
          } else {
            notification(res.error);
          }
        } else {
          notification("Success");
          this.setState({
            currentEntity: res
          });
        }
      });
  };
  deleteButtonOCL = (e)=>{
    e.preventDefault();
    const { notification, changeRoute } = this.props.context;
    if(window.confirm(`Are you sure you want to delete this ${this.props.resource.entity}?`)) {
      this.props.resource.delete(this.state.currentEntityId)
        .then(()=>{
          notification("Success");
          // this.dispatch.updatePosts();
          changeRoute(`/admin/${this.props.resource.entity}/`);
          window.location.reload();
        });
    }
  };
  cancelButtonOCL = (e)=>{
    e.preventDefault();
    if(window.confirm('Are you sure you want to cancel?')) {
      window.history.back();
    }
  };
  render() {
    const entity = this.props.resource.entity;
    const _buttonBarButtons = [...buttonBarButtons];
    const { theme } = this.props.context;
    const { entityId, editMode } = this.props.match.params;
    const newButton = {name:"New", link:`/admin/${entity}/new`};
    const viewButton = {name:"View", link:`/admin/${entity}/${this.state.currentEntityId}`};
    const editButton = {name:"Edit", link:`/admin/${entity}/${this.state.currentEntityId}/edit`};
    const deleteButton = {name:"Delete", onClick:this.deleteButtonOCL};

    let firstButtonPlaced = false;
    const addDividerIfNeeded = (button) => {
      if(!firstButtonPlaced) {
        button.divider = true;
        firstButtonPlaced = true;
      }
    }

    if(entityId!=="new" && this.props.resource.supports.add) {
      addDividerIfNeeded(newButton);
      _buttonBarButtons.push(newButton);
    }
    if(this.state.currentEntityId) {
      if(this.props.resource.supports.update) {
        if(editMode && editMode==="edit") {
          addDividerIfNeeded(viewButton);
          _buttonBarButtons.push(viewButton);
        } else {
          addDividerIfNeeded(editButton);
          _buttonBarButtons.push(editButton);
        }
      }
      if(this.props.resource.supports.delete) {
        addDividerIfNeeded(deleteButton);
        _buttonBarButtons.push(deleteButton);
      }
    }

    const ukThemeBg = (theme==="dark")?"uk-card-secondary":"uk-card-default";

    return (
      <>
        <ButtonBar buttons={_buttonBarButtons}/>
        <div className={`datapanel-grid uk-animation-fade`}>
          <div className={`datapanel-content`}>
            {(entityId && entityId!=="new" && entityId===this.state.currentEntityId) ? (
              (editMode && editMode==="edit" ? (
                <>
                  <div className={`uk-card ${ukThemeBg} uk-card-body uk-padding uk-margin-bottom`}>
                    {this.props.renderContentEditMode(this.state.currentEntityId, this.state.currentEntity)}
                    <hr/>
                    <div className="uk-flex">
                      {(this.props.resource.supports.update) && (
                        <button className="uk-button uk-button-default uk-button-small uk-margin-right" onClick={this.saveButtonOCL}>Save</button>
                      )}
                      <button className="uk-button uk-button-default uk-button-small uk-margin-right" onClick={this.cancelButtonOCL}>Cancel</button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className={`uk-card ${ukThemeBg} uk-card-body uk-padding uk-margin-bottom`}>
                    {this.props.renderContent(this.state.currentEntityId, this.state.currentEntity)}
                  </div>
                </>
              ))
            ) : (
              (entityId && entityId==="new") ? (
                <>
                  <div className={`uk-card ${ukThemeBg} uk-card-body uk-padding uk-margin-bottom`}>
                    {this.props.renderContentEditMode(this.state.currentEntityId, this.state.currentEntity)}
                    <hr/>
                    <div className="uk-flex">
                      {(this.props.resource.supports.add) && (
                        <button className="uk-button uk-button-default uk-button-small uk-margin-right" onClick={this.newButtonOCL}>Save</button>
                      )}
                      <button className="uk-button uk-button-default uk-button-small uk-margin-right" onClick={this.cancelButtonOCL}>Cancel</button>
                    </div>
                  </div>
                </>
              ) : (
                <div className={`uk-card ${ukThemeBg} uk-card-body uk-padding uk-margin-bottom`}>
                  <h4 className="text-center">Select an item</h4>
                </div>
              )
            )}
          </div>
          <div className={`datapanel-sidebar`}>
            {this.props.renderSidebar()}
          </div>
        </div>
      </>
    );
  }
}
DataPanel = withContext(DataPanel);
DataPanel.propTypes = {
  resource: PropTypes.object.isRequired, //resource service wrapper
  match: PropTypes.object.isRequired, //react-router
  renderSidebar: PropTypes.func.isRequired,
  renderContentEditMode: PropTypes.func.isRequired,
  renderContent: PropTypes.func.isRequired,
};

export default DataPanel;
