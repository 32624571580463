import React, { PureComponent } from 'react';

import DynamicBackground from './DynamicBackground';
import { withContext } from '../state/AppState';

import './Header.scss'

class Header extends PureComponent {
  render() {
    const { theme } = this.props.context;
    const ukThemeClass = (theme==="dark")?"uk-light":"uk-dark";
    return (
      <div className={`uk-flex uk-flex-column header ${ukThemeClass}`}>
        <DynamicBackground/>
        <div className="uk-flex uk-flex-middle uk-flex-center header-content ">
          <h3 className="uk-heading-medium">Shishir Tandale</h3>
        </div>
        {this.props.children}
      </div>
    );
  }
}

export default withContext(Header);
