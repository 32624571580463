import React, { PureComponent } from 'react';
import { Route, Switch, BrowserRouter, withRouter, Redirect } from 'react-router-dom';
import { initializeContext, withContext, ApplicationContext, firebaseApp } from './state/AppState';

import Blog from './pages/Blog';
import Home from './pages/Home';
import LoginPage from './pages/LoginPage';
import AdminPage from './pages/AdminPage';

import Navbar from './components/Navbar';
import Header from './components/Header';

import "./App.scss";

const LogoutAndRedirectHome = withContext(({ context }) => {
  if(context.isAuthenticated) {
    context.signOut();
  }
  return <Redirect to="/"/>;
});

class SinglePageApp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = initializeContext({ history: props.history });
    this.state.updateContext = this.updateContext;
    this.state.updateBackground = this.updateBackground;
  }

  updateBackground = async () => {
    const { apiService } = this.state.services;
    this.setState(()=>({ updatingBackground: true }));
    const res = await apiService.newBackground({ default: false });
    this.setState(()=>({
      background: res.background,
      error: res.error
    }));
  };

  updateContext = (updateFn) => {
    this.setState(updateFn);
  };

  async componentDidMount() {
    await this.updateBackground();
    firebaseApp.auth().onAuthStateChanged(async (user) => {
      if(user) {
        this.setState(()=>({
          isAuthenticated: true,
          authUser: user
        }));
        //update services so they pull the appropriate data
        this.state.services.apiService.authenticateUser(await user.getIdToken())
        this.state.services.blogService.changeAuthState(true);
        this.state.services.dynamicSectionService.changeAuthState(true);
        if(!user.displayName) {
          const name = window.prompt('What is your display name?');
          if(name) {
            await user.updateProfile({ displayName: name });
          }
        }
      } else if(this.state.isAuthenticated) {
        this.setState(()=>({
          isAuthenticated: false,
          authUser: null
        }));
        //update services so they pull the appropriate data
        this.state.services.apiService.logoutUser();
        this.state.services.blogService.changeAuthState(false);
        this.state.services.dynamicSectionService.changeAuthState(false);
      }
    })
  }

  render() {
    const { theme } = this.state;
    return (
      <div className={`AppContainer spa-content ${theme}`}>
        <ApplicationContext.Provider value={this.state}>
          <Header>
            <Navbar location={this.props.location}/>
          </Header>
          <main className="App">
            <Switch location={this.props.location}>
              <Route exact path="/" component={Home}/>
              <Route path="/blog/:postId" component={Blog}/>
              <Route exact path="/blog" component={Blog}/>
              <Route exact path="/login" component={withContext(LoginPage)}/>
              <Route exact path="/logout" component={LogoutAndRedirectHome}/>
              <Route path="/admin" component={AdminPage} />
            </Switch>
          </main>
        </ApplicationContext.Provider>
      </div>
    );
  }
}

const SPAWithRouter = withRouter(SinglePageApp);

const RoutedApp = () => (
  <BrowserRouter>
    <SPAWithRouter/>
  </BrowserRouter>
);

export default RoutedApp;
