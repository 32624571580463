import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Route, Switch, Link} from 'react-router-dom';
import Quill from 'quill';

import Footer from '../components/Footer';
import { LabeledInput, LabeledTextArea, QuillField } from '../util/FormUtil';
import { PostsFactory, renderPostAsCard } from '../components/DynamicPosts';
import DataPanel, { ButtonBar, buttonBarButtons } from '../components/DataPanel';
import { formatPostDate } from '../services/BlogService';
import Resource from '../services/Resource';
import { withContext, withSecureContext } from '../state/AppState';


const BlogPostPanel = withContext((props) => {
  const { blogService } = props.context.services;
  const bsr = new Resource(blogService);
  return (
    <DataPanel resource={bsr}
      renderSidebar={()=>(
        <PostsFactory render={renderPostAsCard({linkTo:(post=>`/admin/blogpost/${post.id}`)})}/>
      )}
      renderContentEditMode={(currentEntityId, currentEntity)=>(
        <Fragment>
          <LabeledInput className="uk-input" defautValue={currentEntity.title} id="title_field" label="Title"/><br/>
          {/*<LabeledTextArea className="form-control" defautValue={currentEntity.post} id="post_field" label="Post:"/><br/>*/}
          <QuillField id="post_field" readOnly={false} content={currentEntity.post ? JSON.parse(currentEntity.post) : []}/><br/>
          <LabeledInput className="uk-checkbox" type="checkbox" defaultChecked={!!currentEntity.public} id="post_is_public_field" label="Show to Public"/>
        </Fragment>
      )}
      renderContent={(currentEntityId, currentEntity)=>(
        <Fragment>
          <h3 className="uk-card-title">{currentEntity.title}</h3>
          <QuillField id="content-field" readOnly={true} content={JSON.parse(currentEntity.post)}/>
          <hr/>
          <p className="uk-text-small uk-text-normal">{`Created on ${formatPostDate(currentEntity.created_date)}`}</p>
          <p className="uk-text-small uk-text-normal">{`Last Updated on ${formatPostDate(currentEntity.updated_date)}`}</p>
          <p className="uk-text-small uk-text-normal">{currentEntity.public?'Post is shown to the public.':'Post is hidden from the public.'}</p>
        </Fragment>
      )}
      processEditModeFields={(currentEntityId, currentEntity)=>{
        const title = document.getElementById('title_field').value;
        const postIsPublic = document.getElementById('post_is_public_field').checked;
        const quillField = new Quill(document.getElementById('post_field'));
        const modifiedPost = Object.assign({}, currentEntity);
        modifiedPost.title = title;
        modifiedPost.post =  JSON.stringify(quillField.getContents()["ops"]);
        modifiedPost.public = postIsPublic;
        return modifiedPost;
      }}
      {...props} />
  );
});

const DynamicSectionPanel = withContext((props) => {
  const { dynamicSectionService } = props.context.services;
  const dssr = new Resource(dynamicSectionService);
  return (
    <DataPanel resource={dssr}
      renderSidebar={()=>(
        <DynamicSectionFactory render={renderDynSection({linkTo:(ds=>`/admin/dynamic_section/${ds.id}`)})}/>
      )}
      renderContentEditMode={(currentEntityId, currentEntity)=>(
        <Fragment>
          <LabeledInput className="uk-input" defautValue={currentEntityId} id="id_field" label="Id"/><br/>
          <LabeledInput className="uk-input" defautValue={currentEntity.description} id="desc_field" label="Description"/><br/>
          <LabeledTextArea className="uk-textarea" defautValue={currentEntity.value} id="value_field" label="Value"/><br/>
          <LabeledInput className="uk-checkbox" type="checkbox" defaultChecked={!!currentEntity.public} id="is_public_field" label="Show to Public"/>
        </Fragment>
      )}
      renderContent={(currentEntityId, currentEntity)=>(
        <Fragment>
          <h3 className="uk-card-title">{currentEntityId}</h3>
          <p className="uk-text-small uk-text-normal">{currentEntity.description}</p>
          <p className="uk-text-small uk-text-normal">{`Last Updated on ${formatPostDate(currentEntity.updated_date)}`}</p>
          <p className="uk-text-small uk-text-normal">{currentEntity.public?'Section can be shown to the public.':'Section is hidden from the public.'}</p>
          <hr/>
          <div dangerouslySetInnerHTML={{__html: currentEntity.value}}/>
        </Fragment>
      )}
      processEditModeFields={(currentEntityId, currentEntity)=>{
        const id = document.getElementById('id_field').value;
        const desc = document.getElementById('desc_field').value;
        const value = document.getElementById('value_field').value;
        const isPublic = document.getElementById('is_public_field').checked;
        const obj = Object.assign({}, currentEntity);
        obj.description = desc;
        obj.value =  value;
        obj.id =  id;
        obj.public = isPublic;
        return obj;
      }}
      {...props} />
  );
});

class DynamicSectionFactory extends PureComponent {
  state = {
    dynamicSections: null
  };
  async componentDidMount() {
    const { dynamicSectionService } = this.props.context.services;
    const ds = await dynamicSectionService.getObjectsPage(1);
    this.setState(()=>({
      dynamicSections: ds
    }));
  }
  render() {
    const renderFn = this.props.render;
    const { theme } = this.props.context;
    return (
      <Fragment>
        {this.state.dynamicSections ? this.state.dynamicSections.map(renderFn(theme)) : (
          <div className="uk-margin-large">
            <h4 className="uk-text-meta">Loading...</h4>
          </div>
        )}
      </Fragment>
    );
  }
}
DynamicSectionFactory = withContext(DynamicSectionFactory);
DynamicSectionFactory.propTypes = {
  render: PropTypes.func.isRequired
};

const renderDynSection =
  ({linkTo=(post=>`/blog/${post.id}`)}) =>
  (theme) =>
  (ds,i) => {
    const ukThemeBg = (theme==="dark")?"uk-card-secondary":"uk-card-default";
    return (
      <div className={`uk-card ${ukThemeBg} uk-card-body uk-margin-small`} key={`post_${i}`}>
        <Link to={linkTo(ds)} style={{color:"unset"}}>
          <p className="uk-text-bolder">{ds.id}</p>
        </Link>
      </div>
    );
};

const HomePanel = withContext(({ context }) => {
  const { theme, authUser } = context;
  const ukThemeBg = (theme==="dark")?"uk-card-secondary":"uk-card-default";
  const user = authUser;
  return (
    <Fragment>
      <ButtonBar buttons={buttonBarButtons}/>
      <div className={`uk-card uk-card-body ${ukThemeBg}`}>
        <h3 className="uk-card-title">Site Administration</h3>
        {user.displayName ? (
          <p className="uk-text-lead">
            {`Welcome, ${user.displayName}.`}
          </p>
        ) : (
          <p className="uk-text-lead">
            Welcome. You have not yet set-up your display name.
          </p>
        )}

        <p>
          This page is used to manage the various types of objects
          that are used through out the site. Click an object type
          above to get started.
        </p>
      </div>
    </Fragment>
  );
});

class AdminPage extends PureComponent {
  render() {
    return (
      <div className="uk-container uk-container-expand uk-animation-fade">
        <Switch location={this.props.location}>
          <Route exact path="/admin" component={HomePanel}/>
          <Route path="/admin/blogpost/:entityId?/:editMode?" component={BlogPostPanel}/>
          <Route path="/admin/dynamic_section/:entityId?/:editMode?" component={DynamicSectionPanel}/>
        </Switch>
        <Footer/>
      </div>
    );
  }
}

export default withSecureContext(AdminPage);
