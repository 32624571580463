import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { withContext } from '../state/AppState';
import "./Navbar.scss";

const navLinks = [
  {link: "/", name: "Home", public: true},
  {link: "/blog", name: "Blog", public: true},
  {link: "/admin", name: "Admin", public: false},
  {link: "/logout", name: "logout", public: false}
];

class Navbar extends PureComponent {
  render() {
    const { location, context } = this.props;
    const { theme, isAuthenticated } = context;
    const ukThemeClass = (theme==="dark")?"uk-light":"uk-dark";
    return (
      <>
        <nav className={`uk-navbar-container uk-navbar-transparent ${ukThemeClass}`} uk-navbar="true">
          <div className="uk-navbar-center">
            <ul className="uk-navbar-nav">
              {navLinks
                .filter(link=>(link.public||isAuthenticated))
                .map(({link, name})=>{
                  let matchCond = location.pathname.startsWith(link);
                  if(link==="/") matchCond = location.pathname===link;
                  return (
                    <li className={matchCond?"uk-active":""} key={`navLink_${link}`}>
                      <NavLink to={link}>{name}</NavLink>
                    </li>
                  );
              })}
            </ul>
          </div>
        </nav>
      </>
    );
  }
}
Navbar = withContext(Navbar);
Navbar.propTypes = {
  location: PropTypes.object.isRequired
};

export default Navbar;
