
class Resource {
  entity = null;
  service = null;

  supports = {
    getById: false,
    getAll: false,
    add: false,
    update: false,
    delete: false
  };

  targets = (ctx) => ({
    getById: null,
    getAll: null,
    add: null,
    update: null,
    delete: null
  });

  constructor(service, entity="default-entity", supports={}, targets={}) {
    if(service && service.resourceAutoDiscovery) {
      this.service = service;
      Object.entries(service.resourceAutoDiscovery).forEach(([key, val])=>{ this[key] = val; });
      this.targets = this.targets(service);
    } else {
      this.service = service;
      this.entity = entity;
      this.supports = supports;
      this.targets = this.targets(service);
    }
  }

  getById = (id) => {
    if (this.supports.getById) return this.targets.getById(id);
    else throw new Error("Unsupported operation");
  }
  getAll = () => {
    if (this.supports.getAll) return this.targets.getAll();
    else throw new Error("Unsupported operation");
  }
  add = (entity) => {
    if (this.supports.add) return this.targets.add(entity);
    else throw new Error("Unsupported operation");
  }
  update = (id, entity) => {
    if (this.supports.update) return this.targets.update(id, entity);
    else throw new Error("Unsupported operation");
  }
  delete = (id) => {
    if (this.supports.delete) return this.targets.delete(id);
    else throw new Error("Unsupported operation");
  }
}

export default Resource;
