import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import Footer from '../components/Footer';
import { formatPostDate } from '../services/BlogService';
import { PostsFactory, renderPostAsCard } from '../components/DynamicPosts';
import { QuillField } from '../util/FormUtil';
import { withContext } from '../state/AppState';

import './Blog.scss';

class Blog extends PureComponent {
  state = {
    recentPosts: null,
    currentPost: null,
    currentPostId: null,
    loading: false
  }
  async componentDidUpdate(prevProps, prevState) {
    if(this.props.match.params !== prevProps.match.params) {
      await this.refreshPost();
    }
  }
  async componentDidMount() {
    if(this.props.match && this.props.match.params) {
      await this.refreshPost();
    }
    //get recent posts
    const { services } = this.props.context;
    const res = await services.blogService.getObjectsPage(1, 3);
    if(res && res.length>0) {
      this.setState(()=>({
        recentPosts: res
      }));
    }
  }
  refreshPost = async () => {
    const { postId } = this.props.match.params;
    const { blogService } = this.props.context.services;
    if(postId) {
      this.setState(()=>({ loading:true }));
      const res = await blogService.getObject(postId);
      this.setState(()=>({
        currentPost: res,
        currentPostId: postId,
        loading: false
      }));
    } else {
      this.setState(()=>({
        currentPost: null,
        currentPostId: null
      }));
    }
  };
  render() {
    const { theme } = this.props.context;
    const { recentPosts } = this.state;
    const ukThemeBg = (theme==="dark")?"uk-card-secondary":"uk-card-default";
    return (
      <>
        <div className="uk-container uk-container-small">
          <div className="recent-events">
            <div className="recent-events-content">
              {this.state.currentPostId ? (
                <div className={`uk-card ${ukThemeBg} uk-card-body uk-padding uk-margin-bottom uk-animation-fade`}>
                  <h3 className="uk-card-title uk-margin-top">{this.state.currentPost.title}</h3>
                  <QuillField id="post-content" readOnly={true} content={JSON.parse(this.state.currentPost.post)}/>
                  <hr/>
                  <p className="uk-text-small uk-text-normal">{`Created on ${formatPostDate(this.state.currentPost.created_date)}`}</p>
                </div>
              ) : (
                <>
                  {recentPosts && (
                    <div className={`uk-card ${ukThemeBg} uk-card-body uk-padding uk-margin-bottom`}>
                      <h2 className="uk-card-title">Recent posts</h2>
                      <ul>
                        {recentPosts.length>=3 && recentPosts[0] &&
                          recentPosts.slice(0, 3).map((p)=>(
                            <li key={`recent_post_link_${p.id}`}>
                              <Link to={`/blog/${p.id}`}>{p.title}</Link>
                            </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <div className={`uk-card ${ukThemeBg} uk-card-body uk-padding uk-margin-bottom`}>
                    <p>Choose a post to view.</p>
                  </div>
                </>
              )}
            </div>

            <div className="recent-events-sidebar uk-animation-fade">
              <PostsFactory render={renderPostAsCard({linkTo:(post=>`/blog/${post.id}`)})}/>
            </div>
          </div>
        </div>
        <Footer/>
      </>
    );
  }
}

export default withContext(Blog);
