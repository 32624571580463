
export class ClassList {
  constructor(classes) {
    this.classes = [];
    classes.forEach((className)=>{this.add(className);});
  }
  add(className) {
    if(className){
      this.classes.push(className);
    }
  }
  toString() {
    return this.classes.join(" ");
  }
}
