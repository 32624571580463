import React, { PureComponent } from 'react';

import { ClassList } from '../util/ReactUtil';
import { withContext } from '../state/AppState';

import './DynamicBackground.scss';

class DynamicBackground extends PureComponent {
  bgImageRef = React.createRef();
  componentDidMount() {
    //fade image in on load
    this.bgImageRef.current.addEventListener('load', (e)=>{
      this.props.context.updateContext(()=>({
        updatingBackground: false
      }));
    });
  }
  render() {
    const { background, updatingBackground } = this.props.context;
    const bgImage = background && background.url;
    const headerBgClass = (new ClassList([
      "dynamic-background",
      updatingBackground ? "hidden" : ""
    ])).toString();
    return (
      <img src={bgImage} className={headerBgClass} ref={this.bgImageRef} alt="Dynamic background which is unrelated to page content"/>
    );
  }
}
DynamicBackground = withContext(DynamicBackground);

export const BackgroundRefreshLink = withContext(({context, className=null}) => {
  const { updateBackground, updatingBackground } = context;
  const linkClassName = (new ClassList([
    "bg-refresh-link",
    className,
    updatingBackground ? "spin" : ""
  ])).toString();

  return (
    <button className={linkClassName} onClick={updateBackground}
      uk-icon="icon: refresh; ratio: 0.5"/>
  );
});

export const BackgroundInfo = withContext(({ context }) => {
  const { background } = context;
  const author = background ? background.author.name : "Unknown";
  const authorLink = background ? background.author.link : undefined;
  const referralPart = '?utm_source=shishir_tandale_com&utm_medium=referral';
  return (
    <span>
      {"Background"}
      {(author==="random")?(<>
        {" randomly picked from "}<a style={{color:"unset"}} target='_blank' rel='noopener noreferrer' href={`https://unsplash.com/${referralPart}`}>Unsplash</a>
      </>):(<>
        {" from "}
        <a style={{color:"unset"}} target='_blank' rel='noopener noreferrer' href={`${authorLink}${referralPart}`}>{author}</a>
        {" using "}
        <a style={{color:"unset"}} target='_blank' rel='noopener noreferrer' href={`https://unsplash.com/${referralPart}`}>Unsplash</a>
      </>)}
      {" "}
      <BackgroundRefreshLink context={context}/>
    </span>
  );
});

export default DynamicBackground;
