
import { AuthenticatedFunctionService } from './Service'

class APIService extends AuthenticatedFunctionService {
    constructor({ siteRoot }) {
        super({ serviceName: 'APIService' });
        this.siteRoot = siteRoot;
    }

    async newBackground(options={}) {
        const res = await fetch(`${this.siteRoot}/api/v1/dynamicBackground`, {
            method: 'POST',
            data: JSON.stringify({
                options
            })
        });
        const body = await res.json();
        return body;
    }
}

export default APIService;