import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { formatPostDate } from '../services/BlogService';
import { withContext } from '../state/AppState';

export class PostsFactory extends PureComponent {
  state = {
    loading: false,
    postsPage: [],
    page: 1
  };
  async componentDidMount() {
    await this.loadPage(this.state.page);
  }
  loadPage = async (page) => {
    const { services, numPostsPerPage } = this.props.context;
    const { blogService } = services;
    if(page>0) {
      this.setState(()=>({ loading: true }));
      const res = await blogService.getObjectsPage(page, numPostsPerPage);
      if(res && res.length>0) {
        this.setState(()=>({
          postsPage: res,
          page: page,
          lastPage: res.length<numPostsPerPage,
          loading: false
        }));
      } else {
        this.setState(()=>({
          loading: false,
          error: `Could not retrieve Posts page ${page}`,
          lastPage: page===this.state.page+1
        }));
      }
    } else {
      this.setState(()=>({
        loading: false,
        error: `Invalid page requested: ${page}`
      }));
    }
  };
  render() {
    const renderFn = this.props.render;
    const { theme } = this.props.context;
    const { page, postsPage } = this.state;
    return (
      <>
        { postsPage.length>0 ? (
          <>
            { postsPage.map(renderFn(theme)) }
            <div className="uk-flex uk-flex-around">
              {page===1 ? (
                <button disabled={true} aria-disabled="true"
                  title="Previous Page"
                  className="uk-button uk-button-secondary">PREV</button>
              ) : (
                <button onClick={()=>{
                  this.loadPage(page-1);
                }} title="Previous Page"
                className="uk-button uk-button-secondary">PREV</button>
              )}
              {this.state.lastPage ? (
                <button disabled={true} aria-disabled="true"
                  title="Next Page"
                  className="uk-button uk-button-secondary">NEXT</button>
              ) : (
                <button onClick={()=>{
                  this.loadPage(page+1);
                }} title="Next Page"
                className="uk-button uk-button-secondary">NEXT</button>
              )}
            </div>
          </>
        ) : (
          <div className="uk-margin-large uk-card uk-card-secondary uk-card-body">
            <p>Loading Page {page}</p>
          </div>
        )}
      </>
    );
  }
}
PostsFactory = withContext(PostsFactory);
PostsFactory.propTypes = {
  render: PropTypes.func.isRequired
};

export const renderPostAsCard =
  ({linkTo=(post=>`/blog/${post.id}`)}) =>
  (theme) =>
  (post,i) => {
    const ukThemeBg = (theme==="dark")?"uk-card-secondary":"uk-card-default";
    return (
      <div className={`uk-card ${ukThemeBg} uk-card-body uk-card-small uk-margin-small`} key={`post_${i}`}>
        <Link to={linkTo(post)} style={{color:"unset"}}>
          <p className="uk-text-bolder">{post.title}</p>
        </Link>
        <p className="uk-text-small">{formatPostDate(post.created_date)}</p>
      </div>
    );
};
