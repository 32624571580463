import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import { BackgroundInfo } from './DynamicBackground';
import { withContext } from '../state/AppState';

import './Footer.scss';

class Footer extends PureComponent {
  render() {
    const { version, env } = this.props.context;
    return (
      <div className={"footer"}>
        <div className="uk-padding">
          <p>
            <BackgroundInfo/>
            <br/>
            Copyright 2019 <Link to="/admin" style={{color:"unset"}}>Shishir Tandale</Link>
            <br/>
            v{version}-{env}
          </p>
        </div>
      </div>
    );
  }
}

export default withContext(Footer);
