import FirestoreCollectionService from './Service';

export const formatPostDate = (postDate) => {
  if(postDate) {
    const seconds = postDate._seconds || postDate.seconds;
    const date = new Date(seconds*1000);
    let numHours = (date.getHours()+1);
    let amPm = "AM";
    if(numHours>12) { numHours -= 12; amPm = "PM"; }
    return `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()} ${numHours}:${date.getMinutes()} ${amPm}`;
  } else {
    return "<never>";
  }
};

class BlogService extends FirestoreCollectionService {
  constructor({ db }) {
    const collectionName = 'posts';
    super({
      objectSortColumn:'created_date',
      objectSortDirection:'desc',
      collectionName,
      db
    });
    this.resourceAutoDiscovery = {
      entity: 'blogpost',
      supports: {
        getById: true,
        getAll: true,
        add: true,
        update: true,
        delete: true
      },
      targets: (ctx) => ({
        getById: ctx.getObject,
        getAll: ctx.getObjectsPage,
        add: ctx.createObject,
        update: ctx.updateObject,
        delete: ctx.deleteObject
      }),
      fields: {
        title: {type:"text"},
        post: {type:"textarea"}
      }
    };
  }
}

export default BlogService;
