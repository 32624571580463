import React, {PureComponent, Fragment} from 'react';
import Quill from 'quill';
import katex from 'katex';

import './FormUtil.scss';

//Quill expects katex to be contained within window
window.katex = katex;

export const LabeledInput = ({
  type="text",
  label="Text:",
  id="exampleId",
  className="",
  defautValue="",
  autoComplete="",
  defaultChecked=""
}) => (
  <Fragment>
    <label className="uk-form-label" htmlFor={id}>{label}</label>
    <div className="uk-form-controls">
      <input type={type} defaultValue={defautValue} id={id} defaultChecked={defaultChecked}
        autoComplete={autoComplete} className={className}/>
    </div>
  </Fragment>
);

export const LabeledTextArea = ({
  label="Text Area:",
  id="exampleId",
  className="",
  defautValue="",
  rows=12
}) => (
  <Fragment>
    <label className="uk-form-label" htmlFor={id}>{label}</label><br/>
    <div className="uk-form-controls">
      <textarea defaultValue={defautValue} rows={rows} id={id} className={className}/>
    </div>
  </Fragment>
);

export const quillToolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote', 'code-block'],
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
  [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'font': [] }],
  [{ 'align': [] }],
  ['link', 'image', 'video', 'formula'],
  ['clean']                                         // remove formatting button
];

export class QuillField extends PureComponent {
  quillFieldRef = React.createRef();
  componentDidMount() {
    const quillOptions = (this.props.readOnly) ? ({
      readOnly: true,
      modules: { toolbar: false }
    }) : ({
      readOnly: false,
      modules: { toolbar: quillToolbarOptions },
      theme: "snow"
    });
    this.editor = new Quill(`#${this.props.id}`, quillOptions);
    if(this.props.content) {
      this.editor.setContents(this.props.content);
    }
  }
  componentWillUnmount() {
    if(!this.props.readOnly) {
      document.querySelector('.ql-toolbar').remove();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if(prevProps.content !== this.props.content) {
      this.editor.setContents(this.props.content);
    }
  }
  render() {
    return (
      <div ref={this.quillFieldRef} id={this.props.id}/>
    )
  }
}

export class LabeledRichTextField extends PureComponent {
  initializeRichTextField = () => {
    this.editor = new Quill(`#${this.props.id}`, {
      modules: { toolbar: quillToolbarOptions },
      theme: "snow",
      readOnly: false
    });
    console.log(this.editor);
    /*const $ = require('jquery');
    require('summernote/dist/summernote-bs4.js');
    $(`#${this.props.id}`).summernote({
      minHeight: 200,
      maxHeight: 600,
      dialogsInBody: true,
      dialogsFade: true,
      foreColor: 'unset',
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'underline', 'clear']],
        ['fontname', ['fontname']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['table', ['table']],
        ['insert', ['link', 'picture', 'video']],
        ['view', ['codeview', 'help']],
      ]
    });*/
  }
  destroyRichTextField = () => {
    //const $ = require('jquery');
    //$(`#${this.props.id}`).summernote('destroy');
  }
  componentDidMount() {
    this.initializeRichTextField();
  }
  componentDidUpdate(prevProps, prevState) {
    //refresh field on update
    this.destroyRichTextField();
    this.initializeRichTextField();
  }
  componentWillUnmount() {
    this.destroyRichTextField();
  }
  render() {
    const {
      label="Text Area:",
      id="exampleId",
      defautValue=""
    } = this.props;
    return (
      <Fragment>
        <label className="uk-form-label" htmlFor={id}>{label}</label><br/>
        <div id={id} dangerouslySetInnerHTML={{__html:defautValue}}/>
        {/*<textarea defaultValue={defautValue} rows={rows} id={id} className={className}/>*/}
      </Fragment>
    )
  }
}
