import React, { PureComponent } from 'react';

import Footer from '../components/Footer';
import { withContext } from '../state/AppState';

const UKCard = ({className, children}) => (
  <div className={`uk-card ${className} uk-card-body uk-margin uk-animation-fade`}>
    {children}
  </div>
);

class Home extends PureComponent {
  state = {
    dynamicContactInfo: null,
    dynamicWelcomeCard: null
  };

  async componentDidMount() {
    await Promise.all([
      this.loadContactInfoSection(),
      this.loadWelcomeSection()
    ]);
  }

  loadContactInfoSection = async () => {
    const { dynamicSectionService } = this.props.context.services;
    const res = await dynamicSectionService.getObject('contact_card')
    this.setState(()=>({
      dynamicContactInfo: res.value
    }));
  }

  loadWelcomeSection = async () => {
    const { dynamicSectionService } = this.props.context.services;
    const res = await dynamicSectionService.getObject('welcome_card')
    this.setState(()=>({
      dynamicWelcomeCard: res.value
    }));
  }

  render() {
    const { theme } = this.props.context;
    const ukCardBg = (theme==="dark")?"uk-card-secondary":"uk-card-default";
    return (
      <div className="uk-container uk-container-xsmall">
        {(this.state.dynamicWelcomeCard) ? (
          <UKCard className={ukCardBg}>
            <div dangerouslySetInnerHTML={{__html: this.state.dynamicWelcomeCard}}/>
          </UKCard>
        ) : (
          <UKCard className={ukCardBg}>
            <h3 className="uk-card-title">Welcome!</h3>
            <p>This page is under construction.</p>
          </UKCard>
        )}
        {(this.state.dynamicContactInfo) ? (
          <UKCard className={ukCardBg}>
            <div dangerouslySetInnerHTML={{__html: this.state.dynamicContactInfo}}/>
          </UKCard>
        ) : (
          <></>
        )}
        <Footer/>
      </div>
    );
  }
}

export default withContext(Home);
