
export class ServicePageError extends Error {
  constructor(service, code, page, details) {
    super(details);
    this.name = "ServicePageError";
    this.code = code;
    this.service = service;
    this.page = page;
  }
}

export class ServiceSearchError extends Error {
  constructor(service, query, code, details) {
    super(details);
    this.query = query;
    this.service = service;
    this.code = code;
    this.name = "ServiceSearchError";
  }
}

export class ServiceObjectError extends Error {
  constructor(service, code, id, details) {
    super(details);
    this.name = "ServiceObjectError";
    this.code = code;
    this.service = service;
    this.objectId = id;
  }
}

export class AuthenticationError extends Error {
  constructor(service, code, details) {
    super(details);
    this.name = "AuthenticationError";
    this.code = code;
    this.service = service;
    this.details = details;
  }
}

export class AuthenticatedFunctionService {
  idToken = null;
  serviceName = null;

  constructor({ serviceName }) {
    this.serviceName = serviceName;
  }

  authenticateUser = (idToken) => {
    this.idToken = idToken;
  };

  logoutUser = () => {
    this.idToken = null;
  };

  secureFetch = (url, options={}) => {
    if(this.idToken===null) {
      throw new AuthenticationError(this.serviceName, 'no-token', `A secure request was attempted but no identity tokens are registered with ${this.serviceName}.`)
    }
    const headers = options.headers || {};
    headers['Authorization'] = `Bearer ${this.idToken}`;
    options.headers = headers;
    return fetch(url, options);
  };
}

class FirestoreCollectionService {
  db = null;
  collectionName = null;
  objectSortColumn = null;
  objectSortDirection = null;
  isAuthenticated = false;
  minSearchLength = 3;
  objectCache = new Map();
  objectPageCache = new Map();
  objectIdToPageCache = new Map();

  constructor({ db, collectionName, objectSortColumn="createdOn", objectSortDirection="desc", objectGetOnlyPublicByDefault=true }) {
    this.collectionName = collectionName;
    this.objectSortColumn = objectSortColumn;
    this.objectSortDirection = objectSortDirection;
    this.objectGetOnlyPublicByDefault = objectGetOnlyPublicByDefault;
    this.db = db;
  }

  changeAuthState = (authState) => {
    this.isAuthenticated = authState;
  };

  objectCollection = (collection=true) => {
    if(collection && this.objectGetOnlyPublicByDefault && !this.isAuthenticated) {
      return this.db.collection(this.collectionName).where('public','==',true);
    } else {
      return this.db.collection(this.collectionName);
    }
  };

  getRecentObjects = async (numObjects=8) => {
    //get all recent Objects
    const snapshot = await this.objectCollection()
      .orderBy(this.objectSortColumn, this.objectSortDirection)
      .limit(numObjects)
      .get();
    const unwrappedData = snapshot.docs.map((doc)=>({id:doc.id, ...doc.data()}));
    //refresh caches
    unwrappedData.forEach(this.processAndCacheObject);
    return unwrappedData;
  };

  getRecentObjectsNextPage = async (lastObject, numObjects=8) => {
    //get all recent Objects
    const snapshot = await this.objectCollection()
      .orderBy(this.objectSortColumn, this.objectSortDirection)
      .limit(numObjects)
      .startAfter(lastObject[this.objectSortColumn])
      .get();
    const unwrappedData = snapshot.docs.map((doc)=>({id:doc.id, ...doc.data()}));
    //refresh caches
    unwrappedData.forEach(this.processAndCacheObject);
    return unwrappedData;
  };

  getObjectsPage = async (pageNum=1, numObjects=8, useCache=true) => {
    if(pageNum<1) {
      //invalid input
      throw new ServicePageError(this.collectionName, "invalid_page", pageNum, "Invalid page specified");
    }
    if(useCache && this.objectPageCache.has(pageNum)) {
      return this.objectPageCache.get(pageNum);
    } else {
      let numCachedPages = this.objectPageCache.size;
      let numPagesToAdd = (pageNum-numCachedPages);
      //only need to load a single page, or reload an existing page
      if(numPagesToAdd<=1) {
        let pageData;
        if(pageNum===1) {
          //first page
          pageData = await this.getRecentObjects(numObjects);
        } else {
          //not the first page; need to get last record of last page, and do a 'startAfter' query
          const lastPageInCache = this.objectPageCache.get(pageNum-1);
          const lastObjFromLastPage = lastPageInCache[lastPageInCache.length-1];
          pageData = await this.getRecentObjectsNextPage(lastObjFromLastPage, numObjects);
        }
        if(pageData.length===0) {
          throw new ServicePageError(this.collectionName, "no_data", pageNum, "Query returned no data");
        }
        for(const page of pageData) {
          this.objectIdToPageCache.set(page.id, pageNum);
        }
        this.objectPageCache.set(pageNum, pageData);
        return pageData;
      }
      //call function recursively to get every page until the target (required to maintain correct order)
      if(numPagesToAdd>1) {
        let pageData;
        while(numPagesToAdd > 0) {
          try {
            pageData = await this.getObjectsPage(numCachedPages+1, numObjects, useCache);
            numCachedPages++;
            numPagesToAdd--;
          } catch(err) {
            throw err;
          }
        }
        return pageData;
      }
    }
  };

  clearPageCacheContainingObject = (objectId) => {
    const pageNum = this.objectIdToPageCache.get(objectId);
    this.objectPageCache.delete(pageNum)
  };
  clearPageCacheContainingObjectAndAllBefore = (objectId) => {
    const pageNum = this.objectIdToPageCache.get(objectId);
    for(let i = pageNum; i >= 1; i--) {
      this.objectPageCache.delete(pageNum);
    }
  };

  search = async(query, props={ byId: true, byName: false, byKeyword: false }) => {
    const { byId, byName, byKeyword } = props;
    let result = null;
    let error = null;
    if(byId) {
      try{
        const resultById = await this.getObject(query);
        result = [resultById];
      } catch(err) {
        if(err.name==="no_data") error = err;
        else throw err;
      }
    }
    if((byName) && query.length>=this.minSearchLength) {
      try{
        const resultByPrefix = await this.findObjectsByPrefix(query.toLowerCase(), "nameLowerCase");
        result = resultByPrefix;
      } catch(err) {
        if(err.name==="no_data") error = err;
        else throw err;
      }
    } else if (query.length<this.minSearchLength) {
      throw new Error(`Query not long enough. Minimum ${this.minSearchLength} characters.`);
    }
    if(byKeyword) {
      try{

      } catch(err) {
        if(err.name==="no_data") error = err;
        else throw err;
      }
    }
    if(result===null) {
      throw error;
    }
    return result;
  }

  findObjectsByPrefix = async (prefix, column="name", limit=10) => {
    const modPrefix = prefix.slice(0,-1) + String.fromCharCode(prefix.charCodeAt(prefix.length-1)+1);
    const snapshot = await this.objectCollection()
      .where(column, '>=', prefix)
      .where(column, '<', modPrefix)
      .limit(limit)
      .get();
    if(snapshot.empty) {
      throw new ServiceSearchError(this.collectionName, `findObjectsByPrefix=${prefix}`, "no_data");
    }
    const unwrappedData = snapshot.docs.map((doc)=>({id:doc.id, ...doc.data()}));
    //refresh caches
    unwrappedData.forEach(this.processAndCacheObject);
    return unwrappedData;
  };

  getObject = async (objectId, useCache=true) => {
    if(useCache && this.objectCache.has(objectId)) {
      return this.objectCache.get(objectId);
    }
    //get this specific document
    const doc = await this.objectCollection(false)
      .doc(objectId)
      .get();
    if(doc.exists) {
      let data = {id:doc.id, ...doc.data()};
      this.processAndCacheObject(data);
      return data;
    } else {
      throw new ServiceObjectError(this.collectionName, "no_data", objectId);
    }
  };

  updateObject = async (objectId, newFields, merge=true) => {
    await this.objectCollection(false)
      .doc(objectId)
      .set(newFields, { merge });
    this.objectCache.delete(objectId);
    this.clearPageCacheContainingObject(objectId);
    return true;
  };

  deleteObject = async (objectId) => {
    await this.objectCollection(false)
      .doc(objectId)
      .delete();
    this.objectCache.delete(objectId);
    this.clearPageCacheContainingObjectAndAllBefore(objectId);
    return true;
  };

  createObject = async (data) => {
    const docRef = await this.objectCollection(false).add(data);
    this.objectPageCache.clear();
    this.objectIdToPageCache.clear();
    return docRef.id;
  };

  processAndCacheObject = async (object) => {
    this.objectCache.set(object.id, object);
  };
}

export default FirestoreCollectionService;
