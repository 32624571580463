import FirestoreCollectionService from './Service';

class DynamicSectionService extends FirestoreCollectionService {
  constructor({ db }) {
    const collectionName = 'dynamic_section';
    super({
      objectSortColumn:'created_date',
      objectSortDirection:'desc',
      collectionName,
      db
    });
    this.resourceAutoDiscovery = {
      entity: collectionName,
      supports: {
        getById: true,
        getAll: true,
        add: true,
        update: true,
        delete: true
      },
      targets: (ctx) => ({
        getById: ctx.getObject,
        getAll: ctx.getObjectsPage,
        add: ctx.createObject,
        update: ctx.updateObject,
        delete: ctx.deleteObject
      }),
      fields: {
        id: {type:"text", readonly:true},
        value: {type:"text"}
      }
    };
  }
}

export default DynamicSectionService;
