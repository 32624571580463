import firebase from 'firebase/app';
import React, { PureComponent } from 'react';

import { LabeledInput } from '../util/FormUtil';

class LoginPage extends PureComponent {
  state = {
    loadingPage: false
  };

  submitForm = async (e) => {
    e.preventDefault();
    const { notification } = this.props.context;
    const email = document.getElementById('emailInput').value;
    const password = document.getElementById('passwordInput').value;
    this.setState(()=>({ loadingPage: true }));
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      notification("Login Successful.");
      this.setState(()=>({ loadingPage: false }));
      window.location.reload();
    } catch(err) {
      notification(`An error occured while logging in:\n${err.message}`);
    }
  }

  render() {
    const { theme, authUser, isAuthenticated } = this.props.context;
    const { loadingPage } = this.state;
    const cardTheme = (theme==="dark") ? "uk-card-secondary" : "uk-card-default";
    return (
      <>
        <div className="uk-container uk-container-xsmall uk-animation-fade">
          <div className={`uk-card ${cardTheme} uk-card-body uk-margin`}>
            <form onSubmit={this.submitForm}>
              <LabeledInput className="uk-input" label="Email:" id="emailInput" autoComplete="email" /><br/>
              <LabeledInput className="uk-input" label="Password:" id="passwordInput" type="password" autoComplete="current-password" /><br/>

              <div className="d-flex justify-content-between">
                <div className="my-2 ml-2">
                  {loadingPage && ("Loading...")}
                  {isAuthenticated && authUser.displayName && (`Welcome, ${authUser.displayName}.`)}
                </div>

                <input type="submit" className={`uk-button uk-button-default`}
                  disabled={loadingPage || isAuthenticated} value="Login"/>
              </div>

            </form>
          </div>
        </div>
      </>
    );
  }
}

export default LoginPage;
