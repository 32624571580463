import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import React from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import { version } from '../../package.json';

import BlogService from '../services/BlogService';
import DynamicSectionService from '../services/DynamicSectionService';
import APIService from '../services/APIService';

import DefaultAuthenticationFallback from '../pages/LoginPage';

//register UIkit Icons globally so they can be used throughout the site
UIkit.use(Icons);

//setup firebase
const firebaseConfig = {
  targetHostingSite: "https://shishirtandale.com",
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "shishir-tandale-com.firebaseapp.com",
  databaseURL: "https://shishir-tandale-com.firebaseio.com",
  projectId: "shishir-tandale-com",
  storageBucket: "shishir-tandale-com.appspot.com",
  messagingSenderId: "348420682882",
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_G_TAG
};
export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const firestoreDb = firebase.firestore(firebaseApp);

//use App as the root for global state
export const ApplicationContext = React.createContext(null);
//HOC to make consuming the global state easier
export const withContext = (Component) => (props) => (
  <ApplicationContext.Consumer>
    {context => <Component {...props} context={context}/>}
  </ApplicationContext.Consumer>
);
//HOC to switch between two components based on authentication state, as well as pass context
export const withSecureContext =
  (Component, Fallback=DefaultAuthenticationFallback) => withContext((props) => (
    props.context.isAuthenticated ? <Component {...props}/> : <Fallback {...props}/>
  ));

//default firebase context, generally suitable for all applications
const defaultFirebaseAppContext = ({ history }) => ({
  //firebase application
  firebase: firebaseApp,
  site: firebaseConfig.targetHostingSite,
  version: version,
  env: 'DEV',
  //firebase auth
  isAuthenticated: false,
  authUser: null,
  signOut: ()=>firebase.auth().signOut(),
  //firestore db
  db: firestoreDb,
  timestamp: firebase.firestore.FieldValue.serverTimestamp(),
  services: {},
  //route manipulation
  changeRoute: (newRoute)=>{history.push(newRoute);},
  goBack: ()=>{history.goBack();},
  //ui manipulation
  modal: UIkit.modal,
  notification: UIkit.notification,
  offcanvas: UIkit.offcanvas,
  util: UIkit.util,
  //error handling stub
  error: console.error
});

//application specific contexts
export const initializeContext = ({ history }) => {
  const context = defaultFirebaseAppContext({ history });
  const services = {};
  const firestoreServiceConfig = { db: firestoreDb };
  services.apiService = new APIService({ siteRoot: firebaseConfig.targetHostingSite });
  services.blogService = new BlogService(firestoreServiceConfig);
  services.dynamicSectionService = new DynamicSectionService(firestoreServiceConfig);
  //install services
  context.services = services;
  //personalization
  context.theme = "dark";
  context.numPostsPerPage = 3;
  context.background = null;
  context.updatingBackground = false;
  return context;
};
